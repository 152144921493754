/**
 * ResetPassword page
 */

// React
import React, { FC } from 'react';

// Material
import { Typography } from '@mui/material';

// Component
import AppInput from 'components/common/AppInput';
import { AppFieldErrors } from 'components/common/AppFieldErrors';

// Constants
import {
  FIELD_NAMES,
  FORM_BUTTON,
  FORM_PLACEHOLDER,
  FORM_RESET_PASSWORD_TITLE_HEADER,
  FORM_RESET_PASSWORD_TITLE_HEADER_SUCCESS,
} from 'utils/constants';

// Common styles
import {
  FieldContainer,
  FormButton,
  FormContainer,
  TypographyCustom,
  WrapperContent,
  WrapperForm,
} from 'styles';

// Hook
import useResetPassword from './useResetPassword';

// Form fields
const formFields = [
  {
    name: FIELD_NAMES.EMAIL,
    placeholder: FORM_PLACEHOLDER.EMAIL_REGISTER_TEMPORARY,
    type: 'email',
  },
  {
    name: FIELD_NAMES.EMAIL_CONFIRMATION,
    placeholder: FORM_PLACEHOLDER.EMAIL_REGISTER_TEMPORARY_CONFIRMATION,
    type: 'email',
  },
];

// ResetPassword component
const ResetPassword: FC = () => {
  const {
    control,
    errors,
    resetSuccess,
    errorMessage,
    onSubmit,
    handleSubmit,
    handleClickBackPage,
  } = useResetPassword();
  const { HEADING, DESCRIPTIONS } = resetSuccess
    ? FORM_RESET_PASSWORD_TITLE_HEADER_SUCCESS
    : FORM_RESET_PASSWORD_TITLE_HEADER;

  const renderResetPassword = () => {
    return (
      <WrapperForm>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <WrapperContent alignItems="start">
            <TypographyCustom variant="h2">{HEADING}</TypographyCustom>
            {DESCRIPTIONS?.map((description, index) => (
              <Typography key={index} variant="h3">
                {description}
              </Typography>
            ))}
          </WrapperContent>

          {formFields.map(({ name, placeholder, type }) => (
            <FieldContainer key={name}>
              <AppInput
                name={name}
                control={control}
                type={type}
                errors={errors}
                placeholder={placeholder}
                helperText={errors?.[name]?.message}
              />
            </FieldContainer>
          ))}

          <AppFieldErrors errorMessage={errorMessage} />

          <FormButton variant="contained" type="submit" color="secondary">
            {FORM_BUTTON.SEND}
          </FormButton>
        </FormContainer>
      </WrapperForm>
    );
  };

  const renderResetPasswordSuccess = () => {
    return (
      <WrapperForm>
        <FormContainer>
          <WrapperContent alignItems="center">
            <TypographyCustom variant="h2">{HEADING}</TypographyCustom>
            {DESCRIPTIONS?.map((description, index) => (
              <Typography key={index} variant="h3">
                {description}
              </Typography>
            ))}
          </WrapperContent>
          <FormButton
            onClick={handleClickBackPage}
            variant="contained"
            type="button"
            color="secondary"
          >
            {FORM_BUTTON.BACK}
          </FormButton>
        </FormContainer>
      </WrapperForm>
    );
  };

  return resetSuccess ? renderResetPasswordSuccess() : renderResetPassword();
};

export default ResetPassword;
