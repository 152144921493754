// Hooks
import useLocalStorage from 'hooks/useLocalStorage';
import { FormRegister } from 'models/RegisterRequest';
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  useForm,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

// Libraries
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// Utils
import {
  HTTP_CODE,
  MAX_LENGTHS,
  NOTIFICATION_MESSAGES,
  PASSWORD_POLICY,
  RESPONSE_CODE,
  STORAGE_KEYS,
  VALIDATION_ATTRIBUTES,
  VALIDATION_MESSAGES,
  ZIPCODE_POLICY,
} from 'utils/constants';
import {
  validateCompanyPhoneNumberNoText,
  validatePassword,
  validateCompanyPhoneNumberMinLength,
  getErrorsMessage,
  replacePhoneNumber,
} from 'utils/helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import AuthService from 'services/AuthService';
import { useBeforeUnload } from 'hooks/useBeforeUnload';

// Router
import ROUTES from 'configs/route';
import { isLoading } from 'recoil/LoadingRecoil';
import { snackNotifyAtom } from 'recoil/SnackNotifyRecoil';

const {
  PASSWORD,
  PASSWORD_CONFIRM,
  COMPANY_NAME,
  LAST_NAME,
  FIRST_NAME,
  LAST_NAME_KANA,
  FIRST_NAME_KANA,
  ZIP_CODE,
  CITY,
  PREFECTURE,
  ADDRESS,
  PHONE_NUMBER,
  COMPANY_PHONE_NUMBER,
  COMMON,
} = VALIDATION_MESSAGES;

const { MIN_LENGTH } = PASSWORD_POLICY;

const schema = yup
  .object({
    password: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_32,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PASSWORD, MAX_LENGTHS.MAX_32)
      )
      .required(PASSWORD.REQUIRED)
      .test('multipleErrorsPassword', validatePassword),
    department: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_30,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.DEPARTMENT, MAX_LENGTHS.MAX_30)
      ),
    building: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_100,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.BUILDING, MAX_LENGTHS.MAX_100)
      ),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], PASSWORD_CONFIRM.MATCH)
      .required(PASSWORD_CONFIRM.REQUIRED),
    company_name: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_30,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.COMPANY_NAME, MAX_LENGTHS.MAX_30)
      )
      .required(COMPANY_NAME.REQUIRED),
    last_name: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_30,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.LAST_NAME, MAX_LENGTHS.MAX_30)
      )
      .required(LAST_NAME.REQUIRED),
    first_name: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_30,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.FIRST_NAME, MAX_LENGTHS.MAX_30)
      )
      .required(FIRST_NAME.REQUIRED),
    last_name_kana: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_30,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.LAST_NAME_KANA, MAX_LENGTHS.MAX_30)
      )
      .required(LAST_NAME_KANA.REQUIRED),
    first_name_kana: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_30,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.FIRST_NAME_KANA, MAX_LENGTHS.MAX_30)
      )
      .required(FIRST_NAME_KANA.REQUIRED),
    zipcode: yup
      .string()
      .max(MAX_LENGTHS.MAX_10, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.ZIPCODE, MAX_LENGTHS.MAX_10))
      .required(ZIP_CODE.REQUIRED)
      .matches(/^\d+$/, COMMON.ONLY_NUMBER)
      .min(ZIPCODE_POLICY.MIN_LENGTH, ZIP_CODE.MIN_LENGTH),
    city: yup
      .string()
      .max(MAX_LENGTHS.MAX_50, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.CITY, MAX_LENGTHS.MAX_50))
      .required(CITY.REQUIRED),
    prefecture: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_5,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PREFECTURE, MAX_LENGTHS.MAX_5)
      )
      .required(PREFECTURE.REQUIRED),
    address: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_200,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.ADDRESS, MAX_LENGTHS.MAX_200)
      )
      .required(ADDRESS.REQUIRED),
    phone_number: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_15,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PHONE_NUMBER, MAX_LENGTHS.MAX_15)
      )
      .test('matches_no_text', PHONE_NUMBER.ISNUMBER, validateCompanyPhoneNumberNoText)
      .required(PHONE_NUMBER.REQUIRED)
      .min(MIN_LENGTH, PHONE_NUMBER.MIN_LENGTH),
    company_phone_number: yup
      .string()
      .max(
        MAX_LENGTHS.MAX_15,
        COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.COMPANY_PHONE_NUMBER, MAX_LENGTHS.MAX_15)
      )
      .test('matches_no_text', COMPANY_PHONE_NUMBER.ISNUMBER, validateCompanyPhoneNumberNoText)
      .test('min_length', COMPANY_PHONE_NUMBER.MIN_LENGTH, validateCompanyPhoneNumberMinLength),
  })
  .required();

interface useRegisterProps {
  username: string;
  confirmCode: string;
  expiredTime: string;
}

interface UseRegisterReturnType {
  control: Control<FormRegister, any>;
  handleSubmit: UseFormHandleSubmit<FormRegister, undefined>;
  errors: FieldErrors<FormRegister>;
  onSubmit: (_: FormRegister) => Promise<void>;
  setError: UseFormSetError<FormRegister>;
  clearErrors: UseFormClearErrors<FormRegister>;
  setValue: UseFormSetValue<FormRegister>;
  disableForm: boolean;
  setDisableForm: React.Dispatch<React.SetStateAction<boolean>>;
  formData: FormRegister;
  checkboxPrivacyPolicy: (_: any) => void;
  checkboxTermOfUse: (_: any) => void;
  disableButtonSubmit: boolean;
  errorMessage: string[] | null;
}

export const useRegister = ({
  username,
  confirmCode,
  expiredTime,
}: useRegisterProps): UseRegisterReturnType => {
  const [formData, setFormData] = useLocalStorage<FormRegister>(STORAGE_KEYS.USER_REGISTER);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors, isValid, isDirty },
    setValue,
    watch,
  } = useForm<FormRegister>({
    defaultValues: {
      email: formData?.email || '',
      password: formData?.password || '',
      password_confirmation: formData?.password_confirmation || '',
      company_name: formData?.company_name || '',
      last_name: formData?.last_name || '',
      first_name: formData?.first_name || '',
      last_name_kana: formData?.last_name_kana || '',
      first_name_kana: formData?.first_name_kana || '',
      zipcode: formData?.zipcode || '',
      city: formData?.city || '',
      prefecture: formData?.prefecture || '',
      building: formData?.building || '',
      phone_number: formData?.phone_number || '',
      address: formData?.address || '',
      company_phone_number: formData?.company_phone_number || '',
      department: formData?.department || '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const userInfoWatch = watch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const setIsLoading = useSetRecoilState(isLoading);
  const setSnackNotify = useSetRecoilState(snackNotifyAtom);

  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [errorMessage, setErrorsMessage] = useState<string[] | null>(null);

  const disableButtonSubmit = !privacyPolicy || !termsOfUse || !isValid;

  const getInfoCompany = async (email: string) => {
    const newDataUser: Partial<FormRegister> = {
      username,
      email,
    };

    try {
      const { company_name = '' } = await AuthService.checkCompany({ email });

      reset({
        email,
        company_name: company_name,
      });

      newDataUser.company_name = company_name;
      newDataUser.is_company_name = !!company_name;
    } catch (error) {
      reset({ email });
    }
    setFormData((prev) => ({ ...prev, ...newDataUser }));
  };

  const verifyEmailAndSetData = async () => {
    try {
      setIsLoading(true);
      const res = await AuthService.verifyEmail({ username, confirm_code: confirmCode });
      const email = res?.email;

      await getInfoCompany(email);
    } catch (error: any) {
      const responseStatus = error.response?.status;
      const responseCode = error.response?.data?.results?.error_code;

      if (
        responseStatus === HTTP_CODE.UNPROCESSABLE &&
        responseCode === RESPONSE_CODE.CONFIRM_CODE_EXPIRED
      ) {
        navigate(ROUTES.VERIFY_CODE_EXPIRED, { state: { expiredTime } });
        return;
      }
      navigate(ROUTES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useBeforeUnload(isDirty, () => {
    setFormData((prev) => ({
      ...prev,
      ...userInfoWatch,
      username,
    }));
  });

  const checkboxPrivacyPolicy = (event: any) => {
    setPrivacyPolicy(event.target.checked);
  };

  const checkboxTermOfUse = (event: any) => {
    setTermsOfUse(event.target.checked);
  };

  const onSubmit = async (data: any) => {
    setErrorsMessage(null);
    if (!disableForm) {
      setDisableForm(true);
      setFormData((prev) => ({
        ...prev,
        ...data,
      }));
    } else {
      try {
        setIsLoading(true);

        await AuthService.register({
          ...data,
          company_phone_number: replacePhoneNumber(data?.company_phone_number || ''),
          phone_number: replacePhoneNumber(data?.phone_number || ''),
        });

        setIsLoading(false);
        navigate(ROUTES.REGISTER_COMPLETED);
      } catch (error: any) {
        setIsLoading(false);
        setSnackNotify({
          open: true,
          severity: 'error',
          title: NOTIFICATION_MESSAGES.REGISTER.ERROR,
        });

        const errorsMessage = getErrorsMessage(error);
        setErrorsMessage(errorsMessage);
      }
    }
  };

  useEffect(() => {
    if (username && confirmCode && username !== formData?.username) {
      verifyEmailAndSetData();
    }
  }, [searchParams]);

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
    disableForm,
    formData,
    setError,
    clearErrors,
    setValue,
    setDisableForm,
    checkboxPrivacyPolicy,
    checkboxTermOfUse,
    disableButtonSubmit,
    errorMessage,
  };
};
