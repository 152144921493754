/**
 * Validation messages
 */

// Define validation messages with uppercase and underscores for keys
export const VALIDATION_MESSAGES = {
  EMAIL: {
    INVALID: 'メールアドレス形式が不正',
    MAX_LENGTH: 'メールアドレスは50文字以下のみ有効です',
    REQUIRED: 'メールアドレスは必須です',
  },
  EMAIL_CONFIRM: {
    MATCH: 'メールアドレスはメールアドレス(確認用)と同じ場合のみ有効です',
    INVALID: 'メールアドレス形式が不正',
    REQUIRED: 'メールアドレス(確認用)は必須です',
  },
  PASSWORD: {
    MIN_LENGTH: 'パスワード10文字以上のみ有効です',
    MAX_LENGTH: 'パスワードは32文字以下のみ有効です',
    REQUIRE_NUMBER: '数字を含める必要があります（0-9）。',
    REQUIRE_UPPERCASE: '大文字を含める必要があります（A-Z）。',
    REQUIRE_LOWERCASE: '小文字を含める必要があります（a-z）。',
    REQUIRE_SPECIAL_CHAR:
      "特殊文字を含める必要があります（~ ! @ # $ % ^ & * ( ) _ + '} {' [ ] \\ ? : \" ; ' , . / = -）。",
    REQUIRED: 'パスワードは必須です',
  },
  PASSWORD_CONFIRM: {
    MATCH: 'パスワード(確認用)はパスワードと同じ場合のみ有効です',
    REQUIRED: 'パスワード(確認用)は必須です',
  },
  COMPANY_NAME: {
    REQUIRED: '会社名は必須です',
  },
  LAST_NAME: {
    REQUIRED: '姓は必須です',
  },
  FIRST_NAME: {
    REQUIRED: '名は必須です',
  },
  LAST_NAME_KANA: {
    REQUIRED: 'セイは必須です',
  },
  FIRST_NAME_KANA: {
    REQUIRED: 'メイは必須です',
  },
  ZIP_CODE: {
    REQUIRED: '郵便番号は必須です',
    MIN_LENGTH: '郵便番号は7文字である必要があります',
  },
  CITY: {
    REQUIRED: '郡市区は必須です',
  },
  PREFECTURE: {
    REQUIRED: '都道府県は必須です',
  },
  BUILDING: {
    REQUIRED: '建物名称は必須です',
  },
  DEPARTMENT: {
    REQUIRED: '所属は必須です',
  },
  PHONE_NUMBER: {
    REQUIRED: '電話番号は必須です',
    INVALID: '電話番号は0x0から始める必要があります',
    ISNUMBER: '半角数字のみ有効です',
    MIN_LENGTH: '電話番号は10文字以上のみ有効です',
  },
  COMPANY_PHONE_NUMBER: {
    REQUIRED: '会社の電話番号は必須です',
    INVALID: '電話番号は0x0から始める必要があります',
    ISNUMBER: '半角数字のみ有効です',
    MIN_LENGTH: '電話番号は10文字以上のみ有効です',
  },
  ADDRESS: {
    REQUIRED: '番地は必須です',
  },
  ACCOUNT: {
    LOCK: 'パスワードを5回間違えたため、アカウントが一時的にロックされました。',
    ACTION: 'パスワードをリセットしてから利用してください。',
  },
  COMMON: {
    ONLY_NUMBER: '半角数字のみ有効です',
    MAX_LENGTH: (attribute: string, max: number): string =>
      `${attribute}は${max}文字以下のみ有効です`,
  },
};

// Define success and error messages
export const NOTIFICATION_MESSAGES = {
  EMAIL: {
    SUCCESS: {
      CHANGE: 'メールアドレスが正常に変更されました',
      REGIN_REQUIRED: '引き続き使用するには、再度ログインしてください',
    },
    ERROR: {
      ALREADY_EXISTS: 'メールはすでに存在します',
      NOT_FOUND: 'メールが存在しません',
    },
  },
  PASSWORD: {
    ERROR: {
      RESET_FAILED: 'パスワードのリセットに失敗しました',
      CHANGE_FAILED: 'パスワード変更に失敗しました',
    },
  },
  LOGIN: {
    ERROR: {
      INVALID_CREDENTIALS: 'メールアドレスまたはパスワードが違います。',
    },
  },
  REGISTER: {
    SUCCESS: '正常に登録されました',
    ERROR: '登録に失敗しました',
  },
  UPDATE: {
    SUCCESS: '正常に更新されました',
    ERROR: 'アップデートに失敗しました',
  },
  GENERAL: {
    ERROR: {
      SYSTEM: 'システムエラー',
    },
  },
};
