// React
import { useEffect, useRef, useState } from 'react';

// Hooks
import { FormRegister } from 'models/RegisterRequest';
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  useForm,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

// Libraries
import * as yup from 'yup';
// Utils
import {
  MAX_LENGTHS,
  NOTIFICATION_MESSAGES,
  PASSWORD_POLICY,
  VALIDATION_ATTRIBUTES,
  VALIDATION_MESSAGES,
  ZIPCODE_POLICY,
} from 'utils/constants';
import {
  validateCompanyPhoneNumberNoText,
  validatePassword,
  validateCompanyPhoneNumberMinLength,
  getErrorsMessage,
  debouncer,
  replacePhoneNumber,
} from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useBeforeUnload } from 'hooks/useBeforeUnload';

// Router
// import ROUTES from 'configs/route';
import { isLoading } from 'recoil/LoadingRecoil';
import { snackNotifyAtom } from 'recoil/SnackNotifyRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';
import ROUTES from 'configs/route';
import AuthService from 'services/AuthService';

const {
  PASSWORD_CONFIRM,
  LAST_NAME,
  FIRST_NAME,
  LAST_NAME_KANA,
  FIRST_NAME_KANA,
  ZIP_CODE,
  CITY,
  PREFECTURE,
  ADDRESS,
  PHONE_NUMBER,
  COMPANY_PHONE_NUMBER,
  COMMON,
} = VALIDATION_MESSAGES;

const { MIN_LENGTH } = PASSWORD_POLICY;

const schema = yup.object().shape({
  email: yup
    .string()
    .max(MAX_LENGTHS.MAX_50, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.EMAIL, MAX_LENGTHS.MAX_50)),
  building: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_100,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.BUILDING, MAX_LENGTHS.MAX_100)
    ),
  department: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_30,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.DEPARTMENT, MAX_LENGTHS.MAX_30)
    ),
  password: yup
    .string()
    .max(MAX_LENGTHS.MAX_32, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PASSWORD, MAX_LENGTHS.MAX_32))
    .test('multipleErrorsPassword', validatePassword),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], PASSWORD_CONFIRM.MATCH)
    .notRequired(),
  last_name: yup
    .string()
    .max(MAX_LENGTHS.MAX_30, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.LAST_NAME, MAX_LENGTHS.MAX_30))
    .required(LAST_NAME.REQUIRED),
  first_name: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_30,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.FIRST_NAME, MAX_LENGTHS.MAX_30)
    )
    .required(FIRST_NAME.REQUIRED),
  last_name_kana: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_30,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.LAST_NAME_KANA, MAX_LENGTHS.MAX_30)
    )
    .required(LAST_NAME_KANA.REQUIRED),
  first_name_kana: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_30,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.FIRST_NAME_KANA, MAX_LENGTHS.MAX_30)
    )
    .required(FIRST_NAME_KANA.REQUIRED),
  zipcode: yup
    .string()
    .max(MAX_LENGTHS.MAX_10, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.ZIPCODE, MAX_LENGTHS.MAX_10))
    .required(ZIP_CODE.REQUIRED)
    .matches(/^\d+$/, COMMON.ONLY_NUMBER)
    .min(ZIPCODE_POLICY.MIN_LENGTH, ZIP_CODE.MIN_LENGTH),
  city: yup
    .string()
    .max(MAX_LENGTHS.MAX_50, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.CITY, MAX_LENGTHS.MAX_50))
    .required(CITY.REQUIRED),
  prefecture: yup
    .string()
    .max(MAX_LENGTHS.MAX_5, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PREFECTURE, MAX_LENGTHS.MAX_5))
    .required(PREFECTURE.REQUIRED),
  address: yup
    .string()
    .max(MAX_LENGTHS.MAX_200, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.ADDRESS, MAX_LENGTHS.MAX_200))
    .required(ADDRESS.REQUIRED),
  phone_number: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_15,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PHONE_NUMBER, MAX_LENGTHS.MAX_15)
    )
    .test('matches_no_text', PHONE_NUMBER.ISNUMBER, validateCompanyPhoneNumberNoText)
    .min(MIN_LENGTH, PHONE_NUMBER.MIN_LENGTH)
    .required(PHONE_NUMBER.REQUIRED),
  company_phone_number: yup
    .string()
    .max(
      MAX_LENGTHS.MAX_15,
      COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.COMPANY_PHONE_NUMBER, MAX_LENGTHS.MAX_15)
    )
    .test('matches_no_text', COMPANY_PHONE_NUMBER.ISNUMBER, validateCompanyPhoneNumberNoText)
    .test('min_length', COMPANY_PHONE_NUMBER.MIN_LENGTH, validateCompanyPhoneNumberMinLength),
});

interface useUserInfoReturnType {
  control: Control<FormRegister, any>;
  handleSubmit: UseFormHandleSubmit<FormRegister, undefined>;
  errors: FieldErrors<FormRegister>;
  onSubmit: (_: FormRegister) => Promise<void>;
  setError: UseFormSetError<FormRegister>;
  clearErrors: UseFormClearErrors<FormRegister>;
  setValue: UseFormSetValue<FormRegister>;
  handleClickBackPage: () => void;
  disableButtonSubmit: boolean;
  isDirty: boolean;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateUser: (_: string) => void;
  errorMessage: string[] | null;
}

export const useUserInfo = (): useUserInfoReturnType => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const setIsLoading = useSetRecoilState(isLoading);
  const setSnackNotify = useSetRecoilState(snackNotifyAtom);
  const resetUserState = useResetRecoilState(userInfoAtom);
  const [errorMessage, setErrorsMessage] = useState<string[] | null>(null);
  const debouncedValidateRef = useRef(debouncer());
  const timeoutValidate = 300;

  const userId = userInfo?.id;

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
    setValue,
    reset,
    setFocus,
    watch,
  } = useForm<FormRegister>({
    defaultValues: {
      password: '',
      password_confirmation: '',
      email: '',
      company_name: '',
      last_name: '',
      first_name: '',
      last_name_kana: '',
      first_name_kana: '',
      zipcode: '',
      city: '',
      prefecture: '',
      building: '',
      phone_number: '',
      address: '',
      company_phone_number: '',
      department: '',
    },
  });

  const dataUserInfo = watch();

  const passwordConfirmation = watch('password_confirmation');
  const password = watch('password');

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const disableButtonSubmit = !isValid;

  const onSubmit = async (data: any) => {
    reset(data);
    setErrorsMessage(null);
    try {
      await schema.validate(data, { abortEarly: false });
      setOpenModal(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          if (err?.path) {
            setError(err.path as keyof FormRegister, { message: err.message });
          }
        });
        const firstErrorPath = error.inner[0]?.path;
        if (firstErrorPath) {
          setFocus(firstErrorPath as keyof FormRegister);
        }
      }
    }
  };

  const handleClickBackPage = async () => {
    try {
      navigate(ROUTES.HOME);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (oldPassword: string) => {
    try {
      setIsLoading(true);
      const { password } = dataUserInfo;

      const newData = {
        company_name: dataUserInfo.company_name,
        last_name: dataUserInfo.last_name,
        first_name: dataUserInfo.first_name,
        last_name_kana: dataUserInfo.last_name_kana,
        first_name_kana: dataUserInfo.first_name_kana,
        zipcode: dataUserInfo.zipcode,
        city: dataUserInfo.city,
        prefecture: dataUserInfo.prefecture,
        building: dataUserInfo.building,
        phone_number: replacePhoneNumber(dataUserInfo.phone_number || ''),
        address: dataUserInfo.address,
        company_phone_number: replacePhoneNumber(dataUserInfo.company_phone_number || ''),
        department: dataUserInfo.department,
      };

      await AuthService.changeUserInfo({
        ...newData,
        ...(password && { old_password: oldPassword, new_password: password }),
      });

      setOpenModal(false);
      setIsLoading(false);

      if (password) {
        localStorage.clear();
        resetUserState();
        navigate(ROUTES.LOGIN);
        return;
      }

      setUserInfo({ ...userInfo, ...newData });
      setSnackNotify({
        open: true,
        title: NOTIFICATION_MESSAGES.UPDATE.SUCCESS,
      });
      navigate(ROUTES.DASHBOARD);
    } catch (error: any) {
      setOpenModal(false);
      setIsLoading(false);

      setSnackNotify({
        open: true,
        severity: 'error',
        title: NOTIFICATION_MESSAGES.UPDATE.ERROR,
      });

      const errorsMessage = getErrorsMessage(error);
      setErrorsMessage(errorsMessage);
    }
  };

  const validatePassword = (password: string, passwordConfirmation: string) => {
    debouncedValidateRef.current(() => {
      schema
        .validateAt('password', { password })
        .then(() => {
          clearErrors('password');
        })
        .catch((yupError) => {
          if (yupError instanceof yup.ValidationError) {
            setError('password', {
              type: 'manual',
              message: yupError.message,
            });
          }
        });

      if (passwordConfirmation !== password) {
        setError('password_confirmation', {
          type: 'manual',
          message: PASSWORD_CONFIRM.MATCH,
        });
      } else {
        clearErrors('password_confirmation');
      }
    }, timeoutValidate);
  };

  useBeforeUnload(isDirty, () => {});

  useEffect(() => {
    if (userId) {
      reset({
        email: userInfo?.email || '',
        company_name: userInfo?.company_name || '',
        last_name: userInfo?.last_name || '',
        first_name: userInfo?.first_name || '',
        last_name_kana: userInfo?.last_name_kana || '',
        first_name_kana: userInfo?.first_name_kana || '',
        zipcode: userInfo?.zipcode || '',
        city: userInfo?.city || '',
        prefecture: userInfo?.prefecture || '',
        building: userInfo?.building || '',
        phone_number: userInfo?.phone_number || '',
        address: userInfo?.address || '',
        company_phone_number: userInfo?.company_phone_number || '',
        department: userInfo?.department || '',
      });
    }
  }, [userId]);

  useEffect(() => {
    validatePassword(password, passwordConfirmation);
  }, [passwordConfirmation, password]);

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
    isDirty,
    setError,
    clearErrors,
    setValue,
    handleClickBackPage,
    disableButtonSubmit,
    openModal,
    setOpenModal,
    updateUser,
    errorMessage,
  };
};
