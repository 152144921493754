/**
 * Menu labels
 */

export const MENU_LABELS = {
  USER_ID: 'ユーザ ID',
  ACCOUNT_INFORMATION: 'アカウント情報',
  TERNS_OF_USE: '利用条件',
  PRIVACY_POLICY: 'プライバシーポリシー',
  LOG_OUT: 'ログアウト',
};
